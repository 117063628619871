import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'ALBA-BUSD LP',
    lpAddresses: {
      97: '0x74B1d83Ff94CA36C3BB90b7c4c047aB1AD297308',
      56: '0xa992242749981Dd1b188AFD765D4710EEddA136e',
    },
    tokenSymbol: 'ALBA',
    tokenAddresses: {
      97: '0xc58Df9784A187DcBc11D42CEf058D67E5aADEB33',
      56: '0x84Fd7CC4Cd689fC021eE3D00759B6D255269D538',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'ALBA-BNB LP',
    lpAddresses: {
      97: '0x7F101378a960db78283Ef681FCbbcF7DAb268943',
      56: '0x9B6742066BE28C12402c892d4C7c5bd585F14Ddd',
    },
    tokenSymbol: 'ALBA',
    tokenAddresses: {
      97: '0xc58Df9784A187DcBc11D42CEf058D67E5aADEB33',
      56: '0x84Fd7CC4Cd689fC021eE3D00759B6D255269D538',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 2,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'ALBA',
    lpAddresses: {
      97: '0x74B1d83Ff94CA36C3BB90b7c4c047aB1AD297308',
      56: '0xa992242749981Dd1b188AFD765D4710EEddA136e', // CAKE-BUSD LP
    },
    tokenSymbol: 'ALBA',
    tokenAddresses: {
      97: '0xc58Df9784A187DcBc11D42CEf058D67E5aADEB33',
      56: '0x84Fd7CC4Cd689fC021eE3D00759B6D255269D538',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
]

export default farms
