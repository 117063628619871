export default {
  cake: {
    56: '0x84Fd7CC4Cd689fC021eE3D00759B6D255269D538',
    97: '0xc58Df9784A187DcBc11D42CEf058D67E5aADEB33',
  },
  masterChef: {
    56: '0xec59bf42688DD28720Cf810A7e2557E0BfDBa6A6',
    97: '0x3aefEc225D7F6893dFc7493141150Bdd0Fa0Ed02',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xBaE36c04b80FE01bb1611160B199fACB7E3CdC27',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F',
  },
  busdt: {
    56: '0x55d398326f99059fF775485246999027B3197955',
    97: '0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F',
  },
}
