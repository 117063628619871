import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap-libs/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
      font-family: 'Poppins', sans-serif;
  }
  body {
    // background-color: ${({ theme }) => theme.colors.background};
    background: #0d0d0d;

    img {
      height: auto;
      max-width: 100%;
    }
  }
  *::-webkit-scrollbar {
    width: 5px;
  }
  *::-webkit-scrollbar-thumb {
    background: #fd9919;
    border-radius: 8px;
  }
  .CookieConsent {
    width: 400px !important;
    max-height: 500px !important;
    background-color: #46d5db !important;
    z-index: 10000 !important;
    position: fixed !important;
    right: 30px !important;
    bottom: 30px !important;
    padding: 20px 25px !important;
    box-shadow: 0 2px 6px 0px rgb(0 0 0 / 30%) !important;
    border-radius: 7px !important;
    left : unset !important;
 }
.CookieConsent h3 {
  margin: 0 0 10px;
}
  .home_page {
    background: url("../images/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .img_row img {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    height: 56px;
    width: auto;
  }
  .AvenirNextBold,
  .hvd_comicfont div {
    font-weight: 400;
    line-height: 1.1;
  }
  h2.hvd_comicfont {
    line-height: 0 !important;
    margin: 0;
  }
  .dash_heading {
    font-size: 38px;
    font-weight: 700;
    color: #fd9919;
  }
  .dash_subheading {
    font-size: 16px;
    color: #fff;
    padding: 0 150px;
    font-weight: 400;
  }
  .dash_subheading span {
    font-weight: 500;
    color: #fd9919;
  }
  .white_box {
    box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 20%) !important;
    border-radius: 10px !important;
  }
  .vision_card_panel {
    margin: 10px auto 0;
    padding: 20px 0;
  }
  button {
    outline: none !important;
  }
  .header_btn {
    padding: 10px 20px;
    font-size: 16px;
    box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset !important;
    height: 40px !important;
    // background-color: #fd9919 !important;
    // color: #000;
    letter-spacing: 0px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 600;
  }
  .toggle_wrapper > div:nth-child(2) {
    background-color: ${({ theme }) => theme.colors.backgroundDisabled};
    border-radius: 30px;
    height: 40px;
  }
  .toggle_wrapper > div div:first-child {
    background-color: ${({ theme }) => theme.colors.backgroundDisabled};
  }
.toggle_wrapper > div:nth-child(2) a {
  height: 40px;
}
  // .toggle_wrapper > div div:first-child div {
  //   background-color: #46d5db;
  //   box-shadow: none !important;
  // }
  button.farms_unlock_btn {
    border-radius: 5px;
    outline: none !important;
  }
  button.toggle_btn {
    box-shadow: none !important;
  }
  button {
    box-shadow: none !important;
  }
  .carousel_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 88%;
  }
  .carousel_content h2 {
    font-size: 28px;
    margin-bottom: 10px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: left;
  }
  .carousel_content p {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: #000000;
  }
  .carousel-control-prev-icon {
    background-image: url("../images/prev_icon.png");
  }
  .carousel-control-next-icon {
    background-image: url("../images/next_icon.png");
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 15px;
    height: 26px;
  }
  .carousel-item img {
    margin-left: 50px;
    width: 220px;
  }
  .carousel-control-next,
  .carousel-control-prev {
    opacity: 0.8;
  }
  .carousel-control-prev {
    left: -50px;
  }
  .carousel-control-next {
    right: -50px;
  }
  .dash_head_1 {
    font-size: 24px;
    font-weight: 700;
    color: #29292a;
  }
  button.cap_btn {
    background-color: #fd9919;
    border-radius: 6px;
    font-size: 16px;
    padding: 10px 20px;
    height: auto;
  }
  button.stake_btn {
    background-color: #fd9919;
    border-radius: 6px;
    font-size: 16px;
    padding: 10px 20px;
    height: auto;
  }
  .stats_box h2 {
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 12px;
  }
  .white_box.stats_box {
    background: #fff;
    border: 2px solid #3ccbd1;
  }
  .stats_box_row div:nth-child(2) {
    color: #000;
  }
  .home_nft_box {
    background-color: #a25341;
  }
  .home_nft_box h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .home_nft_box p {
    font-size: 16px;
    color: #ddd;
    line-height: 30px;
  }
  .home_nft_box button {
    background: #fd9919;
    border-radius: 5px;
    font-size: 16px;
    text-transform: uppercase;
    height: auto;
    padding: 12px 25px;
    margin-top: 50px;
  }
  .home_lottery_box_right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .home_lottery_box_right h3 {
    font-size: 24px;
    color: #010000;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 20px;
  }
  .home_lottery_box_right h1 {
    font-size: 50px;
    color: #fff;
    font-weight: 900;
  }
  .home_lottery_box_right p {
    font-size: 16px;
    color: #000000;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: left;
    line-height: 24px;
  }
  .home_lottery_box_right button {
    background: #fd9919;
    border-radius: 8px;
    font-size: 16px;
    text-transform: uppercase;
    height: auto;
    padding: 15px 40px;
  }
  hr.custom_hr {
    border-top: 1px solid #d0f7f9;
    width: 100%;
    margin-top: 5px;
  }
  .home_farm_box h2 {
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 12px;
    font-size: 24px;
    font-weight: 700;
    color: #29292a;
  }
  .home_farm_box {
    background-color: #fff;
  }
  .home_farm_box button {
    width: 200px;
  }
  .farms_label {
    color: #fd9919 !important;
    font-size: 24px !important;
    font-weight: 700;
  }
  .farms_bal_val {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 5px;
  }
  .home_farm_box h5 {
    color: #626262;
    font-size: 14px;
    font-weight: 400;
    margin: 10px 0;
  }
  .farms_right_box {
    margin-left: 12%;
    border-left: 1px solid #dfdfdf;
    padding-left: 50px;
  }
  .home_total_box h3 {
    color: #29292a;
    font-size: 24px;
    font-weight: 700;
  }
  .home_total_box h2 div {
    color: #ffffff;
    font-size: 54px;
    font-weight: 900;
  }
  .home_total_box h5 {
    color: #29292a;
    font-size: 14px;
    font-weight: 600;
  }
  a:hover {
    text-decoration: none !important;
  }
  .menulink:hover {
    background-color: #fd9919;
  }
  a:hover {
    color: #fd9919 !important;
  }
  button.apy_btn {
    background: #311d04 !important;
    margin-right: 8px;
  }
  button.close_btn {
    background: transparent !important;
  }
  button.wallet_btn {
    border-radius: 5px;
  }
  button.wallet_btn:hover {
    opacity: 0.7 !important;
  }
  
  h1.inner_heading {
    font-weight: 700;
    color: #fd9919;
  }
  .fa-copy {
    font-size: 18px;
    margin-left: 5px;
    color: #46d5db;
    cursor: pointer;
  }
  .carousel_card {
    background-color: transparent;
    box-shadow: none !important;
  }
  .carousel-item {
    // padding: 24px;
    border-radius: 10px;
  }
  .carousel-item > .row {
    height: 100%;
    align-items: center;
    justify-content: space-between;
    min-height: 310px;
  }
  .carousel_item_one {
    background-color: #70e3ea;
  }
  .carousel_item_two {
    background-color: #70e3ea;
  }
  .carousel_item_three {
    background-color: #000422;
  }
  .carousel_item_four {
    background-color: #110145;
  }
  .carousel_item_three .carousel_content p,
  .carousel_item_four .carousel_content p {
    color: #fff
  }
  .ct-particle-animate {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.ct-particle-animate div {
    position: absolute;
    top: 0;
    left: 0;
}
.ct-particle-animate #ct_particle_animate-a461fd71 {
    left: 7%;
    top: 18%;
}
.ct-particle-animate .shape-animate1 {
    animation: animationFramesTwo 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd72 {
    left: 25%;
    top: 10%;
}
.ct-particle-animate .shape-animate2 {
    animation: animationFramesOne 15s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd73 {
    left: 18%;
    top: 45%;
}
.ct-particle-animate #ct_particle_animate-a461fd83 {
    left: 18%;
    top: 15%;
}
.ct-particle-animate .shape-animate3 {
    animation: animationFramesOne 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd74 {
    left: 72%;
    top: 50%;
}
.ct-particle-animate #ct_particle_animate-a461fd84 {
    left: 70%;
    top: 25%;
}
.ct-particle-animate .shape-animate4 {
    animation: animationFramesFour 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd75 {
    left: 74%;
    top: 24%;
}
.ct-particle-animate .shape-animate5 {
    animation: animationFramesThree 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd77 {
    left: 20%;
    top: 70%;
}
.ct-particle-animate #ct_particle_animate-a461fd87 {
    left: 20%;
    top: 35%;
}
.ct-particle-animate .shape-left-right {
    animation: NewsletterAnimated2 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd76 {
    left: 90%;
    top: 60%;
}
.ct-particle-animate #ct_particle_animate-a461fd86 {
    left: 90%;
    top: 29%;
}
.ct-particle-animate .shape-right-left {
    animation: NewsletterAnimated1 12s infinite linear alternate;
}
@keyframes animationFramesOne {
    0% {
        transform: translate(0, 0) rotate(0);
    }
    20% {
        transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        transform: translate(111px, 72px) rotate(72deg);
    }
    60% {
        transform: translate(93px, 122px) rotate(108deg);
    }
    80% {
        transform: translate(-70px, 72px) rotate(124deg);
    }
    100% {
        transform: translate(0, 0) rotate(0);
    }
}
@-webkit-keyframes animationFramesOne {
    0% {
        -webkit-transform: translate(0, 0) rotate(0);
    }
    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        -webkit-transform: translate(111px, 72px) rotate(72deg);
    }
    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        -webkit-transform: translate(-40px, 72px) rotate(124deg);
    }
    100% {
        -webkit-transform: translate(0, 0) rotate(0);
    }
}
@keyframes animationFramesTwo {
    0% {
        transform: translate(0, 0) rotate(0) scale(1);
    }
    20% {
        transform: translate(73px, -1px) rotate(36deg) scale(0.9);
    }
    40% {
        transform: translate(141px, 72px) rotate(72deg) scale(1);
    }
    60% {
        transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    }
    80% {
        transform: translate(-40px, 72px) rotate(104deg) scale(1.1);
    }
    100% {
        transform: translate(0, 0) rotate(0) scale(1);
    }
}
@-webkit-keyframes animationFramesTwo {
    0% {
        -webkit-transform: translate(0, 0) rotate(0) scale(1);
    }
    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg) scale(0.9);
    }
    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg) scale(1);
    }
    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    }
    80% {
        -webkit-transform: translate(-40px, 72px) rotate(104deg) scale(1.1);
    }
    100% {
        -webkit-transform: translate(0, 0) rotate(0) scale(1);
    }
}
@keyframes animationFramesThree {
    0% {
        transform: translate(165px, -179px);
    }
    100% {
        transform: translate(-346px, 200px);
    }
}
@-webkit-keyframes animationFramesThree {
    0% {
        -webkit-transform: translate(165px, -179px);
    }
    100% {
        -webkit-transform: translate(-346px, 200px);
    }
}
@keyframes animationFramesFour {
    0% {
        transform: translate(-300px, 151px) rotate(0);
    }
    100% {
        transform: translate(251px, -200px) rotate(180deg);
    }
}
@-webkit-keyframes animationFramesFour {
    0% {
        -webkit-transform: translate(-300px, 151px) rotate(0);
    }
    100% {
        -webkit-transform: translate(251px, -200px) rotate(180deg);
    }
}
@keyframes animationFramesFive {
    0% {
        transform: translate(61px, -99px) rotate(0);
    }
    21% {
        transform: translate(4px, -190px) rotate(38deg);
    }
    41% {
        transform: translate(-139px, -200px) rotate(74deg);
    }
    60% {
        transform: translate(-263px, -164px) rotate(108deg);
    }
    80% {
        transform: translate(-195px, -49px) rotate(144deg);
    }
    100% {
        transform: translate(-1px, 0) rotate(180deg);
    }
}
@-webkit-keyframes animationFramesFive {
    0% {
        -webkit-transform: translate(61px, -99px) rotate(0);
    }
    21% {
        -webkit-transform: translate(4px, -190px) rotate(38deg);
    }
    41% {
        -webkit-transform: translate(-139px, -200px) rotate(74deg);
    }
    60% {
        -webkit-transform: translate(-263px, -164px) rotate(108deg);
    }
    80% {
        -webkit-transform: translate(-195px, -49px) rotate(144deg);
    }
    100% {
        -webkit-transform: translate(-1px, 0) rotate(180deg);
    }
}
@keyframes animationFramesSix {
    0% {
        transform: translate(31px, -45px) rotate(0);
    }
    21% {
        transform: translate(4px, -80px) rotate(19deg);
    }
    41% {
        transform: translate(-70px, -100px) rotate(36deg);
    }
    60% {
        transform: translate(-133px, -84px) rotate(54deg);
    }
    80% {
        transform: translate(-98px, -24px) rotate(72deg);
    }
    100% {
        transform: translate(-1px, 0) rotate(180deg);
    }
}
@keyframes NewsletterAnimated1 {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-40px);
    }
    50% {
        transform: translateX(0);
    }
    75% {
        transform: translateX(40px);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes NewsletterAnimated2 {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(40px);
    }
    50% {
        transform: translateX(0);
    }
    75% {
        transform: translateX(-40px);
    }
    100% {
        transform: translateX(0);
    }
}



  @media screen and (max-width: 799px) {
    .dash_subheading{
      padding: 0 15px;
      line-height: 24px;
    }
  }
  @media screen and (max-width: 559px) {
    .carousel_content{
      width: 100%;
    }
    .carousel_content h2{
      text-align: center;
    }
    .carousel_content p{
      text-align: center;
    }
    .carousel-item img{
      margin-left: 0;
      margin-bottom: 15px;
    }
    .toggle_wrapper {
      display: block !important;
      text-align: center;
    }
    .toggle_wrapper > div {
      margin-bottom: 15px;
    }
    .carousel-control-prev{
      left: -25px;
    }
    .carousel-control-next{
      right: -25px;
    }
    .farms_right_box{
      padding-left: 0;
      margin-left: 0;
      margin-top: 15px;
    }
  }
`

export default GlobalStyle
