import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://albaswap.com/#/swap',
    calloutClass: 'menulink'
  },
   {
    label: 'About',
    icon: 'AboutIcon',
    href: '#',
    calloutClass: 'menulink'
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    calloutClass: 'menulink',
    items: [
      {
        label: 'Exchange',
        href: 'https://albaswap.com/#/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://albaswap.com/#/pool',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://farms.albaswap.com/farms',
    calloutClass: 'menulink'
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: 'https://farms.albaswap.com/pools',
    calloutClass: 'menulink'
  },
  {
    label: 'IDO',
    icon: 'InfoIcon',
    href: '#',
  },
  // {
  //   label: 'Shop',
  //   icon: 'ShopIcon',
  //   href: '#',
  // },
  {
    label: 'NFT',
    icon: 'NftIcon',
    href: '#',
    calloutClass: 'menulink'
  },
  {
    label: 'Lottery',
    icon: 'TicketIcon',
    href: '#',
    calloutClass: 'menulink'
  },
  {
    label: 'Contact',
    icon: 'ContactIcon',
    href: '#',
    calloutClass: 'menulink'
  },
  {
    label: 'Privacy Policy',
    icon: 'privacyIcon',
    href: '#',
    calloutClass: 'menulink'
  },
  // {
  //   label: 'Contact',
  //   icon: 'ContactIcon',
  //   href: '/',
  //   calloutClass: 'menulink'
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'Swap',
  //       href: '#',
  //     },
  //     {
  //       label: 'CoinGecko',
  //       href: '#',
  //     },
  //     {
  //       label: 'CoinMarketCap',
  //       href: '#',
  //     },
  //     {
  //       label: 'AstroTools',
  //       href: '#',
  //     },
  //   ],
  // },
  // {
  //   label: 'More',
  //   icon: 'MoreIcon',
  //   calloutClass: 'menulink',
  //   items: [
  //     {
  //       label: 'Blog',
  //       href: '#',
  //     },
  //   ],
  // },
]

export default config
